import { useEffect, useState, useContext } from "react";
import useProduct from "./useProducts";
import { USER_DATA } from "../querys";
import { FilterContext } from "../context/FilterContext";
import { useQuery } from "@apollo/client";
import _ from "underscore";

const useDataSheet = () => {
  
  const { data: userData } = useQuery(USER_DATA, {
    variables: { id: 1 },
  });

  const { setTempDataFilter } = useContext(FilterContext);
  const productOrder = useProduct();
  let dataFiltProduct = [];
  const [dataProducts, setDataProducts] = useState([]);
  useEffect(() => {
    productOrder.forEach((product) => {
      if (
        !dataFiltProduct.find(
          (order) =>
            order.productId.replace(/[^a-zA-Z0-9-_]/g, "") ===
            product.productId.replace(/[^a-zA-Z0-9-_]/g, "")
        )
      ) {
        const {
          articleNumber,
          collectionTag,
          colorNumber_productCategory_,
          customerSpcialOrRegular,
          fabricName,
          detail,
          detailCategory,
          id,
          image,
          material,
          materialCategory,
          materialSummery,
          productDetail,
          productId,
          productSummery,
          regularPrice,
          searchWord,
          weight,
          weightNumber,
          color,
          functions,
          finish,
          construction,
          patternAndDesign,
          finishLookAndFeel,
        } = product;
        const checked = false;
        const SEARCH_WORD = [];
        const SEARCH_WORD_KEY = searchWord ? searchWord.split(",") : [];

        const matSummery = [];
        const matOnlyValue = [];
        const matOnlyCategory = [];

        const detSummery = [];
        const detOnlyValue = [];
        const detOnlyCategory = [];
        const categoryMenu = [];
        const itemMenu = [];

        _.each(
          SEARCH_WORD_KEY,
          _.iteratee((word) => SEARCH_WORD.push(word.trim()))
        );
        _.each(
          collectionTag.split("-"),
          _.iteratee((mat) => {
            if (mat !== "") {
              categoryMenu.push(mat.split(",")[0]);
            }
          })
        );

        _.each(
          collectionTag.split("-"),
          _.iteratee((mat) => {
            if (mat !== "") {
              itemMenu.push(mat.split(",")[1]);
            }
          })
        );
        const SEARCH_TAG = collectionTag
          ? collectionTag
              .split(",")
              .map((el) => el.trim())
              .filter((item, index) => {
                return (
                  collectionTag
                    .split(",")
                    .map((el) => el.trim())
                    .indexOf(item) === index
                );
              })
          : [];
        const SEARCH_MATERIAL = material
          ? material
              .toLowerCase()
              .split(",")
              .map((el) => el.trim())
              .filter((item, index) => {
                return (
                  material
                    .toLowerCase()
                    .split(",")
                    .map((el) => el.trim())
                    .indexOf(item) === index
                );
              })
          : [];

        const SEARCH_WEIGHT = weight
          ? weight
              .toLowerCase()
              .split(",")
              .map((el) => el.trim())
              .filter((item, index) => {
                return (
                  weight
                    .toLowerCase()
                    .split(",")
                    .map((el) => el.trim())
                    .indexOf(item) === index
                );
              })
          : [];

        const SEARCH_DETAIL = detail
          ? detail
              .toLowerCase()
              .split(",")
              .map((el) => el.trim())
              .filter((item, index) => {
                return (
                  detail
                    .toLowerCase()
                    .split(",")
                    .map((el) => el.trim())
                    .indexOf(item) === index
                );
              })
          : [];

        const COLOR_VALUES = color.split(",");

        const PRODUCT_SUMERY = productSummery ? productSummery.split(" ") : "";
        const PRODUCT_DETAIL = productDetail ? productDetail.split(" ") : "";

        dataFiltProduct.push({
          customerSpcialOrRegular,
          colorNumber_productCategory_,
          fabricName,
          productId,
          articleNumber,
          regularPrice,
          productDetail,
          image,
          material,
          materialCategory,
          materialSummery,
          matSummery,
          matOnlyValue,
          matOnlyCategory,
          weight,
          weightNumber,
          detail,
          detailCategory,
          detSummery,
          detOnlyValue,
          detOnlyCategory,
          productSummery,
          id,
          checked,
          collectionTag,
          SEARCH_TAG,
          SEARCH_WORD,
          SEARCH_MATERIAL,
          SEARCH_DETAIL,
          SEARCH_WEIGHT,
          PRODUCT_SUMERY,
          PRODUCT_DETAIL,
          searchWord,
          COLOR_VALUES,
          functions,
          finish,
          construction,
          patternAndDesign,
          finishLookAndFeel,
          categoryMenu,
          itemMenu,
        });
      }
    });

    setTempDataFilter(dataFiltProduct);
    if (userData) {
      if (userData.confirmed) {
        setDataProducts(dataFiltProduct);
      } else {
        setDataProducts(
          dataFiltProduct.filter(
            (customerProducts) =>
              customerProducts.customerSpcialOrRegular === "2"
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);  
  return dataProducts;
};

export default useDataSheet;
