import React from "react";
import UrlShare from "../components/UrlShare";
import queryString from "query-string"
import { Location } from "@reach/router"

const ShareLink = () => {
  return (
    <Location>
      {({ location, navigate }) => {        
        return (
          <UrlShare
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        );
      }}
    </Location>
  );
};

export default ShareLink;
