import React from "react";
import { Location } from "@reach/router";
import queryString from "query-string";

import Search from "../components/templates/Search";
const SearchRoute = ({ current_user }) => {
  const { confirmed } = current_user;    
  return (
    <Location>
      {({ location, navigate }) => {
        return (
          <Search
            current_user={current_user}
            confirmed={confirmed}
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        );
      }}
    </Location>
  );
};

export default SearchRoute;
