import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"
import AfterPayReturn from "../components/templates/afterpay-return"
import { loadStripe } from "@stripe/stripe-js/pure"
import { Elements } from "@stripe/react-stripe-js"
import { STRIPE_PRIVATE_KEY } from "../models/stripe-pk"

const AfterPayClearPay = ({ current_user }) => {
  const stripe = loadStripe(STRIPE_PRIVATE_KEY)
  const { confirmed } = current_user
  return (
    <Location>
      {({ location, navigate }) => {
        return (
          <Elements stripe={stripe}>
            <AfterPayReturn
              confirmed={confirmed}
              location={location}
              navigate={navigate}
              search={location.search ? queryString.parse(location.search) : {}}
            />
          </Elements>
        )
      }}
    </Location>
  )
}

export default AfterPayClearPay
