import React, { createContext, useState } from "react";

export const CartContentContext = createContext(null);

const CartContentProvider = (props) => {
  // const localData = JSON.parse(localStorage.getItem("cart"))
  const [cartContent, setCartContent] = useState([]);
  const [swatchContent, setSwatchContent] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [shippingTotalCost, setShippingTotalCost] = useState(0);
  const [totalAmout, setTotalAmout] = useState(0);
  const [dataCurrentUser, setDataCetcurrentUser] = useState({});
  const [paymentOrder, setPaymentOrder] = useState({});
  return (
    <CartContentContext.Provider
      value={{
        cartContent,
        setCartContent,
        swatchContent,
        setSwatchContent,
        totalPrice,
        setTotalPrice,
        shippingAddress,
        setShippingAddress,
        shippingTotalCost,
        setShippingTotalCost,
        totalAmout,
        setTotalAmout,
        dataCurrentUser,
        setDataCetcurrentUser,
        paymentOrder,
        setPaymentOrder,
      }}
    >
      {props.children}
    </CartContentContext.Provider>
  );
};

export default CartContentProvider;
