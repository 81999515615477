import fetch from "cross-fetch"
import { setContext } from "apollo-link-context"
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { apiBase } from "../services/appenv"
// const URL_LOCAL = "http://localhost:5432/graphql"
const URL_PROD = `${apiBase}/graphql`
const httpLink = createHttpLink({
  uri: URL_PROD,
  fetch,
})
const authLink = setContext((_, { headers }) => {
  // Read the storage
  const token = localStorage.getItem("token")
  return token
    ? {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    : null
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})
export default client