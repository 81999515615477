import React from "react";
import ProductCardType from "./cards/ProductCardType";

const HomeTypeSection = ({ type }) => {
  return (
    <div className="home-type">
      {type === "1" && (
        <>
          <ProductCardType title={"Mare New Arrival"} search={"mare-26ss"} />
          <ProductCardType title={"Takashi's Pick"} search={"t-sports"} />
        </>
      )}
      {type === "2" && (
        <>
          <ProductCardType title={"Shirting"} search={"AmeCazi-Shirt"} />
          <ProductCardType title={"Cotton Outer & Bottom"} search={"AmeCazi-Outer"} />
          <ProductCardType title={"Tech & Sports"} search={"AmeCazi-Tech"} />
          <ProductCardType title={"Suiting"} search={"AmeCazi-Suit"} />
          <ProductCardType title={"Indigo"} search={"AmeCazi-Indigo"} />
          <ProductCardType title={"New Arrivals"} search={"AmeCazi-New"} />
        </>
      )}
      {type === "3" && (
        <>
          <ProductCardType title={"Womens"} search={"womens"} />
          <ProductCardType title={"Takashi's Pick"} search={"t-Womens"} />
        </>
      )}
      {type === "4" && (
        <>
          <ProductCardType title={"Takashi's Pick"} search={"t-Womens"} />
        </>
      )}
      {type !== "2" && (
        <ProductCardType title={"New Arrival"} search={"new-arrival"} />
      )}
    </div>
  );
};

export default HomeTypeSection;
