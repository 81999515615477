import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ORDERS } from "../querys/index";
import Loading from "../components/Loading";
import { customStyles } from "../services/modal";

import Modal from "react-modal";
import { createCharge } from "../services/api";
import { toast } from "react-toastify";

import useDataSheet from "../hooks/useDataSheet";
import Select from "react-select";
import useProducts from "../hooks/useProducts";
import { ADD_CHARGE } from "../mutations/index";
const ConfirmPayment = () => {
  //   const stripeKey = loadStripe(STRIPE_PRIVATE_KEY)
  //   const stripe = useStripe()

  const sheet = useDataSheet();
  const dataGRAPH = useProducts();
  const [dataOrders, setDataOrders] = useState([]);
  const { loading } = useQuery(GET_ORDERS, {
    onCompleted: (data) => {
      setDataOrders(data?.orderhistories);
    },
  });
  const [openModal, setOpenModal] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);

  const [currentOrder, setCurrentOrder] = useState({});

  const handleViewOrder = (order) => {
    setOpenModal(true);
    setCurrentOrder(order);
  };

  const token = localStorage.getItem("token");
  const [addCharge] = useMutation(ADD_CHARGE, {
    onCompleted: () => {},
    onError: () => {},
  });

  const [optionsArticle, setOptionsArticle] = useState([]);
  const [optionsColorNumber, setOptionsColorNumber] = useState([]);
  const [dataultValue, setDataultValue] = useState();
  const [articleNumberValue, setArticleNumberValue] = useState("");
  const handleChangeColor = (color) => {
    setDataultValue({ value: color.value, label: color.label });
  };

  const handleGetColor = (e) => {
    setOptionsColorNumber([]);
    const { value } = e;
    setArticleNumberValue(value);
    const dataColors = dataGRAPH.filter(
      (colors) =>
        colors.productId.replace(/[^a-zA-Z0-9-_]/g, "") ===
        value.replace(/[^a-zA-Z0-9-_]/g, "")
    );

    dataColors.forEach((element) => {
      setOptionsColorNumber((prevState) => [
        ...prevState,
        {
          value: element.colorNumber_productCategory_,
          label: element.colorNumber_productCategory_,
        },
      ]);
    });

    setDataultValue({
      value: dataColors[0].colorNumber_productCategory_,
      label: dataColors[0].colorNumber_productCategory_,
    });
  };

  useEffect(() => {
    sheet.forEach((element) => {
      setOptionsArticle((prevState) => [
        ...prevState,
        {
          value: element.articleNumber,
          label: element.articleNumber,
        },
      ]);
    });
  }, [sheet]);
  const handleCreateCharge = async () => {
    const data = {
      amount: totalAmount,
      currency: "usd",
      source: currentOrder.card_id,
    };

    await createCharge(token, data)
      .then((response) => {
        if (response.status === "succeeded") {
          toast.success("Success saving charge");
          setOpenModal((openModal) => !openModal);
          addChargeOrder(response.id);
        }
      })
      .catch((error) => {
        toast.error("No further charges can be made to this order.");
        setOpenModal((openModal) => !openModal);
      });
    setTotalAmount(0);
  };
  const addChargeOrder = (id) => {
    addCharge({
      variables: {
        id: currentOrder.id,
        input: {
          chargetocard: {
            articlenumber: articleNumberValue,
            colornumber: dataultValue.value,
            quantity: totalAmount,
            amount: totalAmount,
            idcharge: id,
          },
        },
      },
    });
  };

  return (
    <>
      {loading && <Loading />}

      <div className="container confirm-payment-list">
        <p>Order list</p>
        <table>
          <thead>
            <tr>
              <th>Payment ID</th>
              <th>User Name</th>
              <th>Payment amount</th>
              <th>Payment Method</th>
              <th>Company Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataOrders.map((order) => {
              const {
                id_payment,
                amount,
                clientsecret,
                payment_method,
                companyName,
                card_id,
                customer_id,
                username,
              } = order;
              return (
                id_payment &&
                card_id && (
                  <tr>
                    <td>{id_payment}</td>
                    <td>{username}</td>
                    <td>{amount} USD</td>
                    <td>{payment_method}</td>
                    <td>{companyName}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          handleViewOrder(
                            order,
                            clientsecret,
                            card_id,
                            customer_id
                          )
                        }
                      >
                        Add charge
                      </button>
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      </div>

      <Modal isOpen={openModal} style={customStyles}>
        <div className="pay-details">
          <h2>Payment details</h2>
          <p>
            {" "}
            <b>Payment id:</b> {currentOrder.id_payment}
          </p>
          <div className="u-display-flex u-flex-wrap-wrap">
            <p className="u-width-50">
              <b>Payment Method:</b> {currentOrder.payment_method}
            </p>
            <p className="u-width-50">
              <b>Company Name:</b> {currentOrder.companyName}
            </p>
          </div>
          <div className="u-display-flex u-flex-wrap-wrap">
            <p className="u-width-50">
              <b>Amount:</b> {currentOrder.amount} USD
            </p>
            <p className="u-width-50">
              <b>Shipping Cost:</b> {currentOrder.shippingFee} USD
            </p>
          </div>
        </div>

        <table className="table-ship-charge">
          <thead>
            <tr>
              <th>Aricle Number</th>
              <th>Color Number</th>
              <th>Quantity</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {currentOrder?.detailSwatch &&
              currentOrder?.detailSwatch.map((swatch) => (
                <tr>
                  <td>{swatch.articleNumber}</td>
                  <td>{swatch.colorNumber}</td>
                  <td>{swatch.quantity}</td>
                  <td>{swatch.totalPrice} USD</td>
                </tr>
              ))}
          </tbody>
        </table>

        <h2 className="text-add-charge">Add Charge</h2>
        <form className="form-charge">
          <div className="input-charge">
            <label htmlFor="articleNumber">Article Number</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={"Default"}
              isSearchable={true}
              name="articleNumber"
              onChange={(e) => handleGetColor(e)}
              options={optionsArticle}
            />
          </div>
          <div className="input-charge">
            <label htmlFor="colorNumber">Color Number</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={{
                value: dataultValue ? dataultValue.value : "",
                label: dataultValue ? dataultValue.label : "",
              }}
              onChange={(e) => handleChangeColor(e)}
              isSearchable={true}
              name="colorNumber"
              options={optionsColorNumber}
            />
          </div>

          <div className="input-charge">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="text"
              id="quantity"
              placeholder=""
              value={totalAmount}
              onChange={(e) => {
                setTotalAmount(e.target.value);
              }}
            />
          </div>
          <div className="input-charge">
            <label htmlFor="amount">Amount</label>
            <input
              type="text"
              id="amount"
              placeholder=""
              value={totalAmount}
              onChange={(e) => {
                setTotalAmount(e.target.value);
              }}
            />
          </div>
        </form>
        <div className="u-text-center u-display-flex u-margin-top-2 u-justify-center">
          <button
            className="btn btn-danger btn-width-150 u-margin-right-1 u-margin-left-1"
            onClick={() => {
              setOpenModal((openModal) => !openModal);
              setTotalAmount(0);
            }}
          >
            Cancel
          </button>
          <button
            disabled={totalAmount === 0}
            className="btn btn-primary btn-width-150 u-margin-right-1 u-margin-left-1"
            onClick={() => handleCreateCharge()}
          >
            add charge
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmPayment;
