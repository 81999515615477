import { useStaticQuery, graphql } from "gatsby";

const useAddressStates = () => {
  const queryAddress = useStaticQuery(graphql`
    query {
      allGoogleSheetShippingNew(
        sort: { order: ASC, fields: usStates }
        filter: { usStates: { ne: null } }
      ) {
        nodes {
          usStates
        }
      }
    }
  `);
  return queryAddress;
};

export default useAddressStates;
