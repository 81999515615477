import { useStaticQuery, graphql } from "gatsby";

const useAddress = () => {
  const queryAddress = useStaticQuery(graphql`
    query {
      allGoogleSheetShippingNew(
        sort: { order: ASC, fields: area }
        filter: { area: { ne: null } }
      ) {
        nodes {
          area
        }
      }
    }
  `);
  return queryAddress;
};

export default useAddress;
