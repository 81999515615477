import React from "react";
import { Location } from "@reach/router";
import queryString from "query-string";

import RecoverPass from "../components/RecoverPass";

const RecoverPassword = () => {  
  return (
    <Location>
      {({ location, navigate }) => {        
        return (
          <RecoverPass                
            location={location}
            navigate={navigate}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        );
      }}
    </Location>
  );
};

export default RecoverPassword;
