import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Layout = (props) => {  
  const [showLayout, setShowLayout] = useState(false);
  const query = useStaticQuery(graphql`
    {
      allStrapiStyle {
        nodes {
          global
        }
      }
    }
  `);
  const path = typeof window !== "undefined" ? window.location.pathname : "";
  const [aux, setAux] = useState(false);
  const data = query.allStrapiStyle.nodes[0].global;
  useEffect(() => {
    if (path === "/resale-certificate") {
      setAux(true);
    }

    if (props.children.props !== undefined) {
      if (props.children.props.className === "home-flex-content") {
        setShowLayout(true);
      }
    }
    return () => {
      setShowLayout(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style>{data}</style>
      <div>
        <Helmet>
          <title>Textile Library</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />

          {/* <script type="text/javascript">
            {`
    (function (d, w, c) {
      if (!d.getElementById("spd-busns-spt")) {
        var n = d.getElementsByTagName("script")[0],
          s = d.createElement("script")
        var loaded = false
        s.id = "spd-busns-spt"
        s.async = "async"
        s.setAttribute("data-self-init", "false")
        s.setAttribute("data-init-type", "opt")
        s.src = "https://cdn.freshbots.ai/assets/share/js/freshbots.min.js"
        s.setAttribute("data-client", "37b62e2a9e741292822524a4f2a8aad8a9de6bb1")
        s.setAttribute("data-bot-hash", "2d5e3f3625215bc537f1b50a4b0ea4417181d5e2")
        s.setAttribute("data-env", "prod")
        s.setAttribute("data-region", "us")
        if (c) {
          s.onreadystatechange = s.onload = function () {
            if (!loaded) {
              c()
            }
            loaded = true
          }
        }
        n.parentNode.insertBefore(s, n)
      }
    })(document, window, function () {
      Freshbots.initiateWidget(
        {
          autoInitChat: false,
          getClientParams: function () {
            return { "cstmr::eml": "" }
          },
        },
        function (successResponse) {},
        function (errorResponse) {}
      )
    })
    `}
          </script> */}
        </Helmet>
        {aux ? "" : <Header />}
        {props.children}
        {aux ? "" : <Footer></Footer>}
      </div>
    </>
  );
};

export default Layout;
