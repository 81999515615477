import React, { useState, useEffect } from "react";

import _ from "underscore";
import "rc-slider/assets/index.css";

import PriceRangeWidget from "../widgets/price-pange.widget";
import WeightWidget from "../widgets/weight.widget";
import MaterialWidget from "../widgets/material.widget";
import FunctionWidget from "../widgets/function.widget";
import FinishWidget from "../widgets/finish.widget";
import ConstructionWidget from "../widgets/construction.widget";
import PatternDesignWidget from "../widgets/pattern-design.widget";
import FinishLookFeelWidget from "../widgets/finish-look-feel.widget";

import useGetData from "../../hooks/useGetData";

const Filter = (props) => {
  const dataFilter = useGetData();

  const filterItems = dataFilter.allGoogleSheetFilterItems.nodes;

  const [priceRange, setPriceRange] = useState([]);
  const [color, setColor] = useState([]);
  const [materialItem, setMaterialItem] = useState([]);
  const [weightItem, setWeightItem] = useState([]);
  const [construction, setConstruction] = useState([]);
  const [functionItem, setFunctionItem] = useState([]);
  const [finish, setFinish] = useState([]);
  const [patternDesign, setPatternDesign] = useState([]);
  const [finishLookFeel, setFinishLookFeel] = useState([]);

  const { onChangeFilter, filter } = props;

  const {
    category_mat,
    material,
    look_feel,
    finish_item,
    function_item,
    construction_item,
    pattern_design,
  } = props.filter;

  useEffect(() => {
    _.each(
      filterItems,
      _.iteratee((item) => {
        if (item.priceRange)
          setPriceRange((prevState) => [...prevState, item.priceRange]);
        if (item.color) setColor((prevState) => [...prevState, item.color]);
        if (item.weight)
          setWeightItem((prevState) => [...prevState, item.weight]);
        if (item.materials)
          setMaterialItem((prevState) => [...prevState, item.materials]);
        if (item.function)
          setFunctionItem((prevState) => [...prevState, item.function]);
        if (item.finish) setFinish((prevState) => [...prevState, item.finish]);
        if (item.construction)
          setConstruction((prevState) => [...prevState, item.construction]);
        if (item.pattern__Design)
          setPatternDesign((prevState) => [...prevState, item.pattern__Design]);

        if (item.finishLook__Feel)
          setFinishLookFeel((prevState) => [
            ...prevState,
            item.finishLook__Feel,
          ]);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentDetailItems =
    Object.keys(filter).length && filter.detail ? filter.detail.split(",") : [];

  const currentWeightItems =
    Object.keys(filter).length && filter.weight ? filter.weight.split(",") : [];

  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [selectedWeight, setSelectedWeight] = useState(currentWeightItems);

  const handleSelectWeight = ({ id }) => {
    let current_data = currentWeightItems;
    setSelectedWeight((prevSelected) => {
      current_data = [...prevSelected];
      if (current_data.includes(id))
        return current_data.filter((item) => item !== id);
      else current_data.push(id);
      return current_data;
    });
    // handleShowFilter()
    document.body.parentNode.style.overflow = "auto";
  };

  const currentFilterFinish =
    Object.keys(filter).length && filter.finish_item
      ? filter.finish_item.split(",")
      : [];
  const currentFilterLooksFeel =
    Object.keys(filter).length && filter.look_feel
      ? filter.look_feel.split(",")
      : [];
  // const currentColor =
  //   Object.keys(props?.query).length && props?.query.color_item
  //     ? props?.query.color_item.split(",")
  //     : [];
  const currentFilterConstruction =
    Object.keys(filter).length && filter.construction_item
      ? filter.construction_item.split(",")
      : [];
  const currentFilterPatternDesign =
    Object.keys(filter).length && filter.pattern_design
      ? filter.pattern_design.split(",")
      : [];

  const [selectFinish, setSelectFinish] = useState(currentFilterFinish);
  const [selectLookAndFeel, setSelectLookAndFeel] = useState(
    currentFilterLooksFeel
  );
  const [selectFunction, setSelectFunction] = useState("");
  // const [selectColor, setSelectColor] = useState(currentColor);
  const [selectConstruction, setSelectConstruction] = useState(
    currentFilterConstruction
  );
  const [selectPatternDesign, setSelectPatternDesign] = useState(
    currentFilterPatternDesign
  );
  const currentRange =
    Object.keys(props.query).length > 0
      ? [props.query.min_price, props.query.max_price]
      : [];

  const filterByPriceRange = (min_price, max_price) => {
    if (currentRange[0] === min_price && currentRange[1] === max_price) {
      onChangeFilter({ min_price: "", max_price: "" });
    } else {
      onChangeFilter({ min_price: min_price, max_price: max_price });
    }
  };

  const handleSelectMaterial = (value) => {
    let current_data = "";
    setSelectedMaterial((prevSelected) => {
      current_data = [...prevSelected];
      if (current_data.includes(value))
        return current_data.filter((item) => item !== value);
      else current_data.push(value);
      return current_data;
    });
  };

  const filterByConstruction = (category, value) => {
    let current_data = currentFilterConstruction;
    setSelectConstruction((prevSelected) => {
      current_data = [...prevSelected];
      if (current_data.includes(value))
        return current_data.filter((item) => item !== value);
      else current_data.push(value);
      return current_data;
    });
  };

  const filterByPatternDesign = (category, value) => {
    let current_data = currentFilterPatternDesign;
    setSelectPatternDesign((prevSelected) => {
      current_data = [...prevSelected];
      if (current_data.includes(value))
        return current_data.filter((item) => item !== value);
      else current_data.push(value);
      return current_data;
    });
  };

  // const filterByColor = (value) => {
  //   let current_data = currentColor;
  //   setSelectColor((prevSelected) => {
  //     current_data = [...prevSelected];
  //     if (current_data.includes(value))
  //       return current_data.filter((item) => item != value);
  //     else current_data.push(value);
  //     return current_data;
  //   });
  // };

  const filterByFunction = (value) => {
    let current_data = "";
    setSelectFunction((prevSelected) => {
      current_data = [...prevSelected];
      if (current_data.includes(value))
        return current_data.filter((item) => item !== value);
      else current_data.push(value);
      return current_data;
    });
  };

  const filterByFinish = (value) => {
    let current_data = currentFilterFinish;
    setSelectFinish((prevSelected) => {
      current_data = [...prevSelected];
      if (current_data.includes(value))
        return current_data.filter((item) => item !== value);
      else current_data.push(value);
      return current_data;
    });
  };

  const filterByFinishLookFeel = (value) => {
    let current_data = currentFilterLooksFeel;
    setSelectLookAndFeel((prevSelected) => {
      current_data = [...prevSelected];
      if (current_data.includes(value))
        return current_data.filter((item) => item !== value);
      else current_data.push(value);
      return current_data;
    });
  };

  useEffect(() => {
    onChangeFilter({
      material: selectedMaterial.toString(),
      pattern_design: selectPatternDesign.toString(),
      construction_item: selectConstruction.toString(),
      function_item: selectFunction.toString(),
      finish_item: selectFinish.toString(),
      // color_item: selectColor.toString(),
      look_feel: selectLookAndFeel.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedMaterial,
    selectPatternDesign,
    selectConstruction,
    selectFunction,
    selectFinish,
    // selectColor,
    selectLookAndFeel,
  ]);
  
  useEffect(() => {
    if (!material) {
      setSelectedMaterial([]);
    }
    if (!function_item) {
      setSelectFunction([]);
    }

    if (!pattern_design) {
      setSelectPatternDesign([]);
    }
    if (!construction_item) {
      setSelectConstruction([]);
    }
    if (!finish_item) {
      setSelectFinish([]);
    }
    if (!look_feel) {
      setSelectLookAndFeel([]);
    }
  }, [
    material,
    function_item,
    pattern_design,
    construction_item,
    finish_item,
    look_feel,
  ]);

  const handleFilterWeight = (e) => {
    onChangeFilter({
      ...filter,
      category_mat: category_mat || "",
      material: selectedMaterial.toString(),
      detail: currentDetailItems.toString(),
      weight: selectedWeight.toString(),
      w_min: e[0],
      w_max: e[1],
      page: 1,
    });
  };

  return (
    <div className="filter-categories u-padding-lr-3">
      <div className="filter-categories__content">
        <PriceRangeWidget
          min_selected={props.query.min_price}
          max_selected={props.query.max_price}
          rangeData={priceRange}
          handleFilter={filterByPriceRange}
        />
        <WeightWidget
          weightList={weightItem}
          selected={selectedWeight}
          handleSelect={handleSelectWeight}
          w_min={props.query.w_min}
          w_max={props.query.w_max}
          handleSlider={handleFilterWeight}
        />
        {/* <ColorWidget
          selected={selectColor}
          handleFilter={filterByColor}
          color={color}
        /> */}

        <MaterialWidget
          selected={selectedMaterial}
          dataCatMaterial={materialItem}
          handleFilter={handleSelectMaterial}
        />
        <FunctionWidget
          selected={selectFunction}
          handleFilter={filterByFunction}
          functionItem={functionItem}
        />
        <FinishWidget
          selected={selectFinish}
          handleFilter={filterByFinish}
          finish={finish}
        />

        <ConstructionWidget
          selected={selectConstruction}
          construction={construction}
          handleFilter={filterByConstruction}
        />
        <PatternDesignWidget
          selected={selectPatternDesign}
          patternDesign={patternDesign}
          handleFilter={filterByPatternDesign}
        />
        <FinishLookFeelWidget
          selected={selectLookAndFeel}
          finishLookFeel={finishLookFeel}
          handleFilter={filterByFinishLookFeel}
        />
      </div>
    </div>
  );
};

export default Filter;
