import React from "react";

import { Link } from "gatsby";
import { Card } from "./Style/CardStyle";
import { getImageFromDrive } from "../../services/api";
import { RxImage } from "react-icons/rx";

const CardProduct = ({ data }) => {
  const { productsid, image, articlenumber, productsummery } = data;
  return (
    <>
      <Card className="card">
        <Link
          to={`/products/${productsid?.replace(/[^a-zA-Z0-9-_]/g, "")}`}
          style={{ color: "var(--color-primary)" }}
        >
          {image && (
            <>
              {" "}
              {image === "#N/A" ? (
                // <div className="default-image">
                //   <img src={noImage} alt="" />
                // </div>
                ""
              ) : (
                <>
                  {image !== "0" ? (
                    <>
                      <img
                        referrerPolicy="no-referrer"
                        className="image-1x"
                        src={getImageFromDrive(image)}
                        alt=""
                      />
                      <img
                        referrerPolicy="no-referrer"
                        className="image-2x"
                        src={getImageFromDrive(image)}
                        alt=""
                      />
                    </>
                  ) : (
                    <div className="icon-no-image">
                      <RxImage />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div className="pl-5">
            <p>
              <span>{articlenumber}</span>
            </p>
            <div
              className="u-size-text-small "
              dangerouslySetInnerHTML={{
                __html: `<div>${productsummery?.replace(
                  /(\r\n|\n|\r)/gm,
                  "<br />"
                )}</div>`,
              }}
            ></div>
          </div>
        </Link>
      </Card>
    </>
  );
};

export default CardProduct;
