import React, { useContext } from "react";

import { AuthUserContext } from "../context/AuthUser";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { authUser } = useContext(AuthUserContext);
  if (
    !authUser.logged &&
    location.pathname !== `/login` &&
    !localStorage.getItem("token")
  ) {
    window.location.href = "/login";
    return null;
  }
  return <Component {...rest} current_user={authUser?.user} />;
};

export default PrivateRoute;
