import React, { useState } from "react";
import useListMap from "../hooks/useListMap";
import {
  TitleCategory,
  ItemMap,
  TitleItem,
  DescriptionItem,
  ImgItem,
  ImgContentItem,
  TitlePage,
  MapContent,
  MainContent,
  FluidContent,
} from "../components/styled/ItemsMap";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { MapKey } from "../services/appenv";
import { BiSolidRightArrow } from "react-icons/bi";
import { getImageFromDrive } from "../services/api";
import { StickyContainer, Sticky } from "react-sticky";
import Collapse from "react-collapse";

const Map = ({ location, zoom, animation }) => {
  const mapStyles = {
    width: "100%",
    position: "sticky",
    top: "70px",
    right: 0,
    zIndex: "1",
    height: "calc(100vh - 180px)",
    "@media screen and (max-width: 992px)": {
      top: "82px",
    },
  };

  return (
    <LoadScript googleMapsApiKey={MapKey}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoom}
        center={location}
        options={{
          //  disableDefaultUI: true,
          mapTypeControl: false,
        }}
      >
        <Marker position={location} animation={animation} />
      </GoogleMap>
    </LoadScript>
  );
};

const TokyoMaps = () => {
  const ListMapData = useListMap();
  const [location, setLocation] = useState({
    lat: 35.66196799,
    lng: 139.7017574,
  });
  const initialZoom = 15;
  const [zoom, setZoom] = useState(initialZoom);

  const [openIndexes, setOpenIndexes] = useState([]);
  const [animation, setAnimation] = useState("");
  const handleButtonClick = (lat, lng) => {
    setTimeout(() => {
      setAnimation(null);
    }, 1000);
    setZoom(15);
    setLocation({ lat: lat, lng: lng });
  };
  const handleColapseItem = (index, category) => {
    const indexAndCategory = { index, category };
    if (
      openIndexes.some(
        (item) => item.index === index && item.category === category
      )
    ) {
      setOpenIndexes(
        openIndexes.filter(
          (item) => !(item.index === index && item.category === category)
        )
      );
    } else {
      setOpenIndexes([...openIndexes, indexAndCategory]);
    }
  };
  const organizedData = ListMapData.allTokyoMapsListMap.nodes.reduce(
    (acc, item) => {
      const category = item.category.toLowerCase();
      const existingEntry = acc.find((entry) => entry.category === category);
      if (existingEntry) {
        existingEntry.data.push({
          description: item.description,
          title: item.title,
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lng),
          image1: item.image1,
          image2: item.image2,
        });
      } else {
        acc.push({
          category: category,
          data: [
            {
              description: item.description,
              title: item.title,
              lat: parseFloat(item.lat),
              lng: parseFloat(item.lng),
              image1: item.image1,
              image2: item.image2,
            },
          ],
        });
      }
      return acc;
    },
    []
  );

  return (
    <>
      <TitlePage>TOKYO MAP</TitlePage>
      <FluidContent style={{ display: "flex" }} className="container-fluid">
        <StickyContainer className="stycky-content">
          <MainContent>
            {organizedData.map((category) => (
              <>
                <TitleCategory>{category.category.toUpperCase()}</TitleCategory>
                {category.data.map((item, index) => (
                  <ItemMap>
                    <TitleItem>
                      <b>
                        0{index + 1}. {item.title}
                      </b>
                      <div className="detail-buttons">
                        <small
                          onClick={() =>
                            handleColapseItem(index, category.category)
                          }
                          role="button"
                          tabIndex="0"
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              handleColapseItem(index, category.category)
                            }
                          }}
                        >
                          <BiSolidRightArrow
                            style={{
                              transform: `rotate(${
                                openIndexes.some(
                                  (item) =>
                                    item.index === index &&
                                    item.category === category.category
                                )
                                  ? "90deg)"
                                  : "0deg)"
                              }`,
                            }}
                          />{" "}
                          Detail
                        </small>
                        <span
                          onClick={() => handleButtonClick(item.lat, item.lng)}
                          role="button"
                          tabIndex="0"
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              handleButtonClick(item.lat, item.lng)
                            }
                          }}
                        >
                          MAP
                        </span>
                      </div>
                    </TitleItem>
                    <Collapse
                      className="collapse-content"
                      isOpened={openIndexes.some(
                        (item) =>
                          item.index === index &&
                          item.category === category.category
                      )}
                    >
                      <DescriptionItem>
                        {item.description
                          .split(/\b(https?:\S+)\b/g)
                          .map((parte, index) => {
                            if (parte.match(/\bhttps?:\S+/)) {
                              return (
                                <a
                                  key={index}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={parte}
                                >
                                  {parte}
                                </a>
                              );
                            } else {
                              return <span key={index}>{parte}</span>;
                            }
                          })}
                      </DescriptionItem>

                      {item.image1 || item.image2 ? (
                        <ImgContentItem>
                          {item.image1 && (
                            <ImgItem>
                              <img src={getImageFromDrive(item.image1)} alt={"img1_"+item.index}/>
                            </ImgItem>
                          )}
                          {item.image2 && (
                            <ImgItem>
                              <img src={getImageFromDrive(item.image2)}  alt={"img2_"+item.index}/>
                            </ImgItem>
                          )}
                        </ImgContentItem>
                      ) : (
                        ""
                      )}
                    </Collapse>
                  </ItemMap>
                ))}
              </>
            ))}
          </MainContent>

          <MapContent className="sticky-map">
            <Sticky className="container-map" topOffset={100}>
              {({ style }) => (
                <div style={style}>
                  <Map location={location} zoom={zoom} animation={animation} />
                </div>
              )}
            </Sticky>
          </MapContent>
        </StickyContainer>

        {/* <MapContent>
          <Map
            location={location}
            description={description}
            zoom={zoom}
            animation={animation}
          />
        </MapContent> */}
      </FluidContent>
    </>
  );
};

export default TokyoMaps;
