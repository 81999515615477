import React, { useEffect, useState } from "react";
import _ from "underscore";

import { graphql, useStaticQuery } from "gatsby";

const Loader = () => {
  const [suggestions, setSuggestions] = useState([]);

  const data = useStaticQuery(graphql`
    query {
      allStrapiTextsuggestion {
        nodes {
          descripton
          banner {
            url
          }
        }
      }
    }
  `);
  
  const random = _.shuffle(data.allStrapiTextsuggestion.nodes)

  useEffect(() => {
    setSuggestions(random);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  return (
    <div className="loading-init-page">
      <div className="loader-suggestions">
        {/* {suggestions && (
          <>
            <img src={suggestions[suggestions.length - 1]?.banner?.url} alt="" />
            <p>{suggestions[suggestions.length - 1]?.descripton}</p>
          </>
        )} */}
        <div className="loading loading-card"></div>
      </div>
    </div>
  );
};

export default Loader;
