import {  useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "../components/styled/ProductsStyled";

import { FILTER_ORDERID, ORDER_ID } from "../querys/index";
import { getImageFromDrive } from "../services/api";
import { navigate } from "gatsby";

import noImage from "../images/icons/no-image.svg";
import { AuthUserContext } from "../context/AuthUser";

const InputField = ({ label, name, value, onChange, placeholder }) => (
  <div className="input-order-field">
    <label className="" htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className=""
    />
  </div>
);

const OrderHistoryInfoDetail = ({ id }) => {
  const [orderID, setOrderID] = useState({});

  const { authUser } = useContext(AuthUserContext);
  console.log(authUser, "authUserauthUserauthUser");
  const { loading: loadingFilter, error: ErrorFilter } = useQuery(
    FILTER_ORDERID,
    {
      variables: { usersPermissionsUserId: authUser?.user?.id },
      onCompleted: (data) => {
        const find_id_order =
          data.usersPermissionsUser.data.attributes.orderhistories.data.filter(
            (item) => item.id === id
          );
        if (find_id_order.length === 0) {
          return navigate("/order-history");
        }
      },
    }
  );

  const { loading, error } = useQuery(ORDER_ID, {
    variables: { orderhistoryId: id },
    onCompleted: (data) => {
      setOrderID(data);
    },
  });

  const token = localStorage.getItem("token");

  const initializeFormData = (data) => ({
    amount: data.amount || "",
    email: data.email || "",
    username: data.username || "",
    shippingFee: data.shippingFee || "",
    companyName: data.companyName || "",
    dateOrder: data.dateOrder || "",
    payment_method: data.payment_method || "",
    expectedDeliveryDate: data.expectedDeliveryDate || "",
    shippingDetails: data.shippingDetails || "",

    shippingAddress: {
      address: data.shippingAddress?.address || "",
      city: data.shippingAddress?.city || "",
      shippingUser: data.shippingAddress?.shippingUser || "",
      phoneNumber: data.shippingAddress?.phoneNumber || "",
      zipcode: data.shippingAddress?.zipcode || "",
      street: data.shippingAddress?.street || "",
    },
    detailProducts: Array.isArray(data.detailProducts)
      ? data.detailProducts.map((product) => ({
          articleNumber: product.articleNumber || "",
          colorNumber: product.colorNumber || "",
          quantity: product.quantity || "",
          totalPrice: product.totalPrice || "",
          regularPrice: product.regularPrice || "",
          image: product.image || "",
        }))
      : [],
    detailSwatch: Array.isArray(data.detailSwatch)
      ? data.detailSwatch.map((swatch) => ({
          articleNumber: swatch.articleNumber || "",
          colorNumber: swatch.colorNumber || "",
          quantity: swatch.quantity || "",
          totalPrice: swatch.totalPrice || null,
          regularPrice: swatch.regularPrice || null,
          image: swatch.image || "",
        }))
      : [],
    status: data.status || "",
  });

  const [formData, setFormData] = useState(
    initializeFormData(orderID?.orderhistory?.data.attributes || {})
  );

  useEffect(() => {
    const newData = orderID?.orderhistory?.data.attributes || {};
    setFormData(initializeFormData(newData));
  }, [orderID]);

  // console.log(formData, "formDataformData");

  if (loading) return <p></p>;
  if (error) return <p>Error loading order data</p>;

  return (
    <Container className="container">
      <div className="row">
        <h2
          className="font-semibold mt-4 u-margin-bottom-1"
          style={{ color: "#555555" }}
        >
          Order Detail #{id}
        </h2>
        <ul style={{ marginLeft: "2px" }}>
          {/* <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Company Name: </b>{" "}
            {formData.companyName}
          </li> */}
          {/* <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Amount: </b>
            {formData.amount}
          </li> */}
          <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Order Date: </b>{" "}
            {new Date(formData.dateOrder).toLocaleDateString()}{" "}
            {new Date(formData.dateOrder).toLocaleTimeString()}
          </li>
          {/* <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Payment Method: </b>
            {formData.payment_method}
          </li> */}
          <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Status: </b>
            <p
              style={{
                display: "inline",
                padding: "2px 8px",
                borderRadius: "5px",
                color: "white",
                background:
                  formData.status === "Ordered"
                    ? "#4A90E2"
                    : formData.status === "Customer Notification"
                    ? "#FFC107"
                    : formData.status === "Shipped (from Japan)"
                    ? "#28A745"
                    : formData.status === "Arrived"
                    ? "#2C3E50"
                    : "#4A90E2",
              }}
            >
              {formData.status}
            </p>
          </li>
          <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Expected Delivery Date:</b>
            {formData.expectedDeliveryDate}
          </li>
          <li style={{ marginBottom: "5px", color: "#454545" }}>
            <b style={{ color: "#555555" }}>Shipping detail:</b>
            <a
              href={formData.shippingDetails}
              target="_blank"
              style={{marginLeft: "5px"}}
              rel="noopener noreferrer"
            >
              {formData.shippingDetails}
            </a>
          </li>
        </ul>

        {formData?.detailProducts.length !== 0 ? (
          <div className="content-scroll-h">
            <h2 className="title-length-order">Length Order</h2>
            <table className="col-lg-12 col-md-12 table-order-content">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Article Number</th>
                  <th>Color Number</th>
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </tr>
              </thead>
              <tbody>
                {formData?.detailProducts.map((product, i) => (
                  <tr key={i}>
                    <td>
                      {product.image ? (
                        <img
                          src={getImageFromDrive(product.image)}
                          alt={product.articleNumber}
                          className="thumbnail-order"
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{product.articleNumber}</td>
                    <td>{product.colorNumber.replaceAll('"', "")}</td>
                    <td>{product.quantity}</td>
                    {/* <td>${product?.totalPrice?.toFixed(2)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
        {formData?.detailSwatch !== 0 ? (
          <div className="content-scroll-h">
            <h2 className="title-length-order">Swatch Order</h2>
            <table className="col-lg-12 col-md-12 table-order-content">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Article Number</th>
                  <th>Color Number</th>
                  <th>Quantity</th>
                  {/* <th>Total Price</th> */}
                </tr>
              </thead>
              <tbody>
                {formData?.detailSwatch.map((product, i) => (
                  <tr key={i}>
                    <td>
                      {product.image ? (
                        <img
                          src={getImageFromDrive(product.image)}
                          alt={product.articleNumber}
                          className="thumbnail-order"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = noImage;
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{product.articleNumber}</td>
                    <td>{product.colorNumber.replaceAll('"', "")}</td>
                    <td>{product.quantity}</td>
                    {/* <td>${product?.totalPrice?.toFixed(2)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}

        {/* <DetailProducts
          products={formData?.detailProducts}
          onChange={handleChange}
        />

        <DetailSwatches
          swatches={formData?.detailSwatch}
          onChange={handleChange}
        /> */}
      </div>
    </Container>
  );
};
export default OrderHistoryInfoDetail;
