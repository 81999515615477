import React, { useContext, useState, useEffect } from "react";
import { Link } from "gatsby";
import { ColTotal, Total, SectionText } from "../components/styled/CartStyled";
import { toast } from "react-toastify";
import { navigate } from "gatsby";
import _ from "underscore";
import cartIcon from "../images/icons/cart.icon.svg";
import ItemCart from "../components/ItemCart";
import { useMutation, useQuery } from "@apollo/client";

import { GET_SHIPPING_CART, UPDATE_SHIPPING_CART } from "../mutations/index";

import { AuthUserContext } from "../context/AuthUser";
import { CartContentContext } from "../context/CartContentContext";

const CartPage = ({ current_user }) => {
  const { authUser } = useContext(AuthUserContext);
  const [updateCart, { loading }] = useMutation(UPDATE_SHIPPING_CART, {
    onCompleted: () => {
      toast.success("Cart updated successfully");
    },
    onError: () => {
      toast.error("There was a problem, the cart could not be updated");
    },
  });
  const { setTotalPrice } = useContext(CartContentContext);
  const [getCartValue, setGetCartValue] = useState([]);

  const { data: cartList } = useQuery(GET_SHIPPING_CART, {
    variables: {
      id: authUser?.user?.id,
    },
    onCompleted: (data) => {
      // setGetidCartpproduct(data.cartItems.data[0]?.id);
    },
  });

  const handleUpdateCart = async (item, add) => {
    const { quantity, productId } = item;

    const factor = add ? 1 : -1;

    const totalQuantity = Math.max(1, parseInt(quantity) + factor);

    try {
      const updatedCartItems =
        cartList?.cartItems?.data[0]?.attributes.cartitem.map(
          ({ __typename, ...rest }) => {
            if (rest.productId === productId) {
              return {
                ...rest,
                quantity: totalQuantity.toString(),
              };
            }
            return rest;
          }
        );

      await updateCart({
        variables: {
          id: cartList?.cartItems?.data[0]?.id,
          data: {
            cartitem: updatedCartItems,
            users_permissions_user: authUser?.user?.id,
          },
        },
        refetchQueries: ["getCartItems", "getCartItem"],
      });
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const [cartValues, setCartValues] = useState();
  useEffect(() => {
    setCartValues(
      _.groupBy(
        cartList?.cartItems?.data[0]?.attributes.cartitem,
        "typeofpurchase"
      )
    );
  }, [authUser, cartList]);
  // console.log(cartValues, " cartValues");

  const handleDeleteItemSwatch = async (item, type) => {
    // console.log(cartList?.cartItems?.data[0].attributes.cartitem, 'itemitemitem')
    try {
      // Filtrar los elementos que no coinciden con el producto a eliminar
      const updatedCartItems =
        cartList?.cartItems?.data[0].attributes.cartitem.filter(
          (order) => order.productId !== item.productId
        );

      await updateCart({
        variables: {
          id: cartList?.cartItems?.data[0]?.id,
          data: {
            cartitem: updatedCartItems.map(({ __typename, ...rest }) => rest), // Eliminar __typename
            users_permissions_user: authUser?.user?.id,
          },
        },
        refetchQueries: ["getCartItems", "getCartItem"],
      });
    } catch (error) {
      console.error("Error deleting item from cart:", error);
    }
  };

  const [totalSwatch, setTotalSwatch] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  useEffect(() => {
    let total = 0;
    cartValues?.swatch?.forEach((item) => {
      total += 1 * parseInt(item.quantity);
      setTotalSwatch(total);
    });
    if (!cartValues?.swatch) {
      setTotalSwatch(0);
    }

    let totalLength = 0;
    cartValues?.length_order?.forEach((item) => {
      // total += item.price * item.quantity;

      total += item.unitprice * parseInt(item.quantity);
      setTotalSwatch(total);
      setTotalPrice(total);
    });
    if (!cartValues?.swatch) {
      setTotalLength(0);
    }
  }, [cartValues, getCartValue]);

  const handleGoShopping = () => {
    if (
      cartValues?.swatch?.length === 0 &&
      cartValues?.length_order?.length === 0
    ) {
      toast.error("Your cart is empty");
    } else {
      navigate("/shopping");
    }
  };
  return (
    <>
      <div className="container">
        <h2 className="head-text-cart">Shopping Cart</h2>
        {cartValues?.length_order?.length === 0 || !cartValues?.length_order ? (
          ""
        ) : (
          <SectionText>Length Order</SectionText>
        )}
        {cartValues?.length_order?.map((item, i) => (
          <ItemCart
            key={i}
            item={item}
            type="product"
            pending={loading}
            handleDeleteItem={() =>
              handleDeleteItemSwatch(item, "length_order")
            }
            handleAddItems={() => handleUpdateCart(item, true)}
            handleLessItems={() => handleUpdateCart(item, false)}
          />
        ))}
        {cartValues?.swatch?.length === 0 || !cartValues?.swatch ? (
          ""
        ) : (
          <SectionText>Swatch Order</SectionText>
        )}

        {cartValues?.swatch?.map((item, i) => (
          <ItemCart
            key={i}
            item={item}
            type="swatch"
            pending={loading}
            handleDeleteItem={() => handleDeleteItemSwatch(item, "swatch")}
            handleAddItems={() => handleUpdateCart(item, true)}
            handleLessItems={() => handleUpdateCart(item, false)}
          />
        ))}

        {!cartValues?.length_order && !cartValues?.swatch ? (
          <div className="col-lg-12 cart-empty">
            <img src={cartIcon} alt="Cart Empty" />
            <p>Your cart is empty</p>
          </div>
        ) : (
          ""
        )}

        {(cartValues?.length_order ||
          cartValues?.length_order?.length === 0 ||
          cartValues?.swatch ||
          cartValues?.swatch?.length === 0) && (
          <>
            <div className="col-lg-12">
              <p className="u-text-affable-primary">
                <br />
                *Length(m): we will inform you actual shipping quantity by
                official confirmation. <br />
                *Subtotal & Total: it's just a rough estimate, we will inform
                you accutual price by official confirmation.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-9"></div>
              <ColTotal className="col-lg-3">
                <Total>
                  <p>Total：</p>
                  <p className="text-price-total">
                    {totalSwatch === 0
                      ? `$ ${parseFloat(totalLength).toFixed(2)}`
                      : `$ ${parseFloat(totalSwatch).toFixed(2)}`}
                  </p>
                </Total>
                <button
                  onClick={() => handleGoShopping()}
                  disabled={loading}
                  type="button"
                  className="btn btn-primary u-text-center btn-link  u-margin-bottom-1"
                >
                  Checkout
                </button>
                <Link
                  to="/products"
                  className="btn u-text-center btn-link-cart"
                >
                  Continue Shopping
                </Link>
              </ColTotal>
            </div>
          </>
        )}
      </div>
      {/* <div className="container">
        
      
         
      </div>

      <Modal isOpen={modalState} style={customStyles}>
        <div className="modal-close">
          <img src={closeImg} onClick={closeModal} />
        </div>
        <div className="modal-delete">
          <h2>Are you sure to delete this swatch from cart?</h2>
          <div className="modal-delete_buttons">
            <button className="btn btn-primary u-size-150" onClick={closeModal}>
              No
            </button>
            <button
              className="btn btn-danger u-size-150"
              onClick={() =>
                deleteSwatch(
                  productID.productid,
                  productID.totalprice,
                  "swatch"
                )
              }
            >
              Yes
            </button>
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default CartPage;
