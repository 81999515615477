import React, { useContext, useState, useEffect, useMemo, useRef } from "react";

import { navigate } from "gatsby";

import Pagination from "react-js-pagination";
import closeIcon from "../../images/icons/close.icon2.svg";
import { IoCloseOutline } from "react-icons/io5";
import { ToggleButton } from "./Style/DetailProductStyle";
import { TbArrowsSort } from "react-icons/tb";
import { BsSliders } from "react-icons/bs";

import { Container } from "../../components/styled/ProductsStyled";
import CardProduct from "../../components/cards/CardProduct";
import Loader from "../Loader";
import { useMutation, useQuery } from "@apollo/client";
import { GET_FAVORITE_ITEMS, GET_PRODUCTS } from "../../querys";
import Filter from "./Filter";
import { GetQuerysContext } from "../../context/GetQuerysContext";
import { AuthUserContext } from "../../context/AuthUser";
import { typeUserManagment } from "../../services/querysData";
import {
  ADD_FAVORITE,
  CREATE_SHIPPING_CART,
  GET_SHIPPING_CART,
  UPDATE_FAVORITE,
  UPDATE_SHIPPING_CART,
} from "../../mutations";
import { toast } from "react-toastify";
import SmallLoader from "../small-loader";
import { ButtonTL } from "../styled/Button";
import HeartLike from "../cards/Style/HeartLike";
const getFilters = (params) => {
  const filter = Object.fromEntries(params.entries());
  return { ...filter };
};

const Search = (props) => {
  const { setAllQuery } = useContext(GetQuerysContext);

  useEffect(() => {
    setAllQuery(props.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search]);

  const params = useMemo(
    () => new URLSearchParams(props.search),
    [props.search]
  );
  const [filter, setFilter] = useState(getFilters(params));
  const filterRef = useRef(filter);
  filterRef.current = filter;

  const updateURL = (newValues) => {
    for (const [key, value] of Object.entries(newValues)) {
      const paramValue = Array.isArray(value) ? value.join(",") : value;
      paramValue ? params.set(key, paramValue) : params.delete(key);
    }
    const paramURL = params.size ? `/?${params}` : "";
    navigate(`/products${paramURL}`);
  };

  const [changePage, setChangePage] = useState(1);

  const {
    w_min = 0,
    w_max = 1000,
    min_price = 0,
    max_price = 10000,
  } = props?.search || {};

  const weightNumber = [parseFloat(w_min), parseFloat(w_max)];
  const priceRage = [parseFloat(min_price), parseFloat(max_price)];

  const handleChangeSustainable = (e) => {
    const isChecked = e.target.checked;
    onChangeFilter({ sustainable: isChecked ? "Sustainable" : "" });
    setStockOperation("");
  };

  const [stockOperation, setStockOperation] = useState("");

  const handleChangeStockOperation = (e) => {
    const isChecked = e.target.checked;
    onChangeFilter({
      stockoperationorbyorder: isChecked ? "Stock Operation" : "By Order",
    });
    setStockOperation("");
  };

  const [sortProducts, setSortProducts] = useState(false);

  const handleSortByPrice = () => {
    const newSortState = !sortProducts;
    setSortProducts(newSortState);
    onChangeFilter({ sort: newSortState ? "ASC" : "DESC" });
  };

  const handleSelectSize = (e) => {
    onChangeFilter({ size: e.target.value || 97 });
  };

  const { authUser } = useContext(AuthUserContext);
  // console.log(authUser?.typeCustomer, "authUser?.typeCustomer");
  const { data: dataProducts, loading } = useQuery(GET_PRODUCTS, {
    variables: {
      page: changePage,
      size: parseInt(props?.search?.size || 97),
      vip: authUser?.typeCustomer && typeUserManagment(authUser.typeCustomer),
      typeSort: props?.search?.sort
        ? `regularprice:${props.search.sort}`
        : "id:ASC",
      material: props?.search?.material || "",
      search: props?.search?.q || "",
      weight: weightNumber || [],

      searchWord: props?.search?.by_fabric?.split("_")[0] || "",
      searchWord2: props?.search?.by_fabric?.split("_")[1] || "",
      searchWord3: props?.search?.by_fabric?.split("_")[2] || "",

      sustainable: props?.search?.sustainable,
      typeOrder: props?.search?.stockoperationorbyorder,
      tag: props?.search?.tag || "",

      collection: props?.search?.collection?.split("_")[1] || "",
      collection2: props?.search?.collection?.split("_")[0] || "",

      functions: props?.search?.function_item || "",
      finish: props?.search?.finish_item || "",
      constructions: props?.search?.construction_item || "",
      patternanddesign: props?.search?.pattern_design || "",
      finishlookandfeel: props?.search?.look_feel || "",
      priceRange: isNaN(priceRage[0]) ? [20, 5000] : priceRage || [],
      weightnumber: weightNumber || [],
    },
  });

  useEffect(() => {}, [dataProducts, props, filter, stockOperation]);

  const onChangeFilter = (filter) => {
    updateURL(filter);
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...filter,
    }));

    if (filter.q?.length === 0) {
      localStorage.setItem("searchTerm", "");
    }
  };

  const handlePageChange = (pageNumber) => {
    window.scrollTo(0, 0);

    setChangePage(pageNumber);
    // onChangeFilter({ ...filter, page: pageNumber });
  };

  const closeFilter = () => {
    const ELEMENT_FILTER = document.querySelector(".selector-filter");
    if (ELEMENT_FILTER.classList.contains("show-filter")) {
      ELEMENT_FILTER.classList.remove("show-filter");
      ELEMENT_FILTER.classList.add("hidde-filter");
    } else {
      ELEMENT_FILTER.classList.add("show-filter");
      ELEMENT_FILTER.classList.remove("hidde-filter");
    }
  };

  const [items, setItems] = useState({});
  const { data: cartList } = useQuery(GET_SHIPPING_CART, {
    variables: {
      id: authUser?.user?.id,
    },
    onCompleted: (data) => {
      setItems(data);
      // setGetidCartpproduct(data.cartItems.data[0]?.id);
    },
  });

  const [addToCart] = useMutation(CREATE_SHIPPING_CART, {
    onCompleted: () => toast.success("Added to cart."),
    onError: () => toast.error("Error adding to cart, please try again"),
  });

  const [updateCart, { updateLoading }] = useMutation(UPDATE_SHIPPING_CART, {
    onCompleted: () => {
      toast.success("Cart updated successfully");
    },
    onError: () => {
      // set && true((prev) => ({ ...prev, [item.id]: false }));
      toast.error("There was a problem, the cart could not be updated");
    },
  });

  useEffect(() => {
    setItems(cartList);
  }, [cartList, updateCart, addToCart]);

  const handleAddToCart = (producto, type) => {
    const {
      productsid,
      colornumber,
      image,
      productsummery,
      regularprice,
      supplierid,
      supplierproductname,
    } = producto.attributes;

    if (
      items &&
      items.cartItems?.data[0]?.attributes?.cartitem?.find(
        (item) => item.productId === productsid
      )
    ) {
      toast.success("The product is already in the cart.");
    } else {
      const cartitem = {
        quantity: "1",
        productId: productsid,
        colortype: colornumber,
        image,
        productSummery: productsummery,
        price: regularprice?.toString(),
        typeofpurchase: type,
        supplierid: supplierid,
        supplierproductname: supplierproductname,
        unitprice: regularprice.toString(),
      };

      if (items.cartItems.data.length === 0) {
        addToCart({
          variables: {
            data: {
              cartitem,
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getCartItems", "getCartItem"],
        });
      } else {
        updateCart({
          variables: {
            id: items?.cartItems?.data[0]?.id,
            data: {
              cartitem: [
                ...items.cartItems.data[0]?.attributes.cartitem.map(
                  ({ __typename, ...rest }) => rest
                ),
                cartitem,
              ],
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getCartItems", "getCartItem"],
        });
      }
    }
  };

  const [favoriteItem, setFavoriteItem] = useState({});
  const { data: favoriteList } = useQuery(GET_FAVORITE_ITEMS, {
    variables: {
      id: authUser?.user?.id,
    },
    onCompleted: (data) => {
      setFavoriteItem(data);
    },
  });

  const [addFavorite] = useMutation(ADD_FAVORITE, {
    onCompleted: () => {
      toast.success("Added to favorites successfully");
    },
    onError: () => {
      toast.error("Failed to add favorite, there was a problem on the server");
    },
  });
  const [updateFavorite] = useMutation(UPDATE_FAVORITE, {
    onCompleted: () => {
      // toast.success("Your favorites were updated");
    },
    onError: () => {
      toast.error("Ocurrio un error al actulizar favorito");
    },
  });

  useEffect(() => {
    setFavoriteItem(favoriteList);
  }, [favoriteList, addFavorite, updateFavorite]);

  const hadnleAddFavorite = (item) => {
    const {
      productsid,
      image,
      articlenumber,
      productsummery,
      colornumber,
      regularprice,
    } = item.attributes;

    if (
      favoriteItem.favorites.data[0]?.attributes.favoritelist.find(
        (item) => item.productsid === productsid
      )
    ) {
      const favoritelist =
        favoriteItem.favorites.data[0]?.attributes.favoritelist
          .filter((item) => item.productsid !== productsid)
          .map(({ __typename, ...rest }) => rest);
      updateFavorite({
        variables: {
          id: favoriteItem.favorites.data[0]?.id,
          data: {
            favoritelist,
            users_permissions_user: authUser?.user?.id,
          },
        },
        refetchQueries: ["getFavoriteItem"],
        onCompleted: () => {
          toast.success("Your favorites were updated");
        },
      });
    } else {
      const favoritelist = {
        productsid: productsid,
        image: image,
        articlenumber: articlenumber,
        productsummery: productsummery,
        colornumber: colornumber,
        regularprice: parseFloat(regularprice),
      };

      if (favoriteItem.favorites.data.length === 0) {
        addFavorite({
          variables: {
            data: {
              favoritelist,
              users_permissions_user: authUser?.user?.id,
            },
          },
          refetchQueries: ["getFavoriteItem"],
        });
      } else {
        updateFavorite({
          variables: {
            id: favoriteItem.favorites.data[0]?.id,
            data: {
              favoritelist: [
                ...favoriteItem.favorites.data[0]?.attributes.favoritelist.map(
                  ({ __typename, ...rest }) => rest
                ),
                favoritelist,
              ],
            },
          },
          refetchQueries: ["getFavoriteItem"],
          onCompleted: () => {
            toast.success("Your favorites were updated");
          },
        });
        // toast.success(`Removed ${productsid} from your favorites`);
      }
    }
  };

  const lastSearchTerm = localStorage.getItem("searchTerm");

  return (
    <div className="container-fluid search-space">
      <div className=" breack-no-padding">
        <div className="row">
          <div
            className="col-xl-2 col-lg-2 col-md-2 col-12 u-padding-right-1"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <div className="content-smarphone-filter selector-filter">
              <div className="filter-categories__content">
                <button
                  className="container-menu__btn-close"
                  onClick={() => closeFilter()}
                >
                  <img src={closeIcon} alt="Close" />
                </button>
                <div className="u-display-flex u-flex-direction-column">
                  <ToggleButton className="u-margin-bottom-1">
                    <input
                      type="checkbox"
                      id="sustainale_only"
                      value="sustainable"
                      onChange={(e) => handleChangeSustainable(e)}
                    />
                    <label htmlFor="sustainale_only">
                      <span>Sustainable Only</span>
                    </label>
                  </ToggleButton>
                  <ToggleButton className="u-margin-bottom-05">
                    <input
                      type="checkbox"
                      id="stock_operation_only"
                      value="stockoperation"
                      onChange={(e) => handleChangeStockOperation(e)}
                    />
                    <label htmlFor="stock_operation_only">
                      <span>Stock Operation Only</span>
                    </label>
                  </ToggleButton>
                </div>
              </div>

              <Filter
                query={props.search}
                filter={filter}
                onChangeFilter={(filter) => {
                  updateURL(filter);
                  setFilter(filter);
                }}
                data={""}
                material={""}
                weight={""}
                detail={""}
                collection={""}
                word={""}
                q={""}
              />
            </div>
          </div>
          <div className="col-xl-10 col-lg-10 col-md-10 col-12 u-padding-left-4">
            <Container className="content-products u-padding-none">
              {lastSearchTerm ? (
                <div className="content-products__keyword">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92.7"
                    height="98.2"
                    baseProfile="tiny"
                    version="1.2"
                    viewBox="0 0 92.7 98.2"
                  >
                    <g>
                      <g id="Layer_1">
                        <g>
                          <circle cx="37" cy="43.6" r="24" fill="#fff" />
                          <path
                            d="M37,69.1c-14.1,0-25.5-11.4-25.5-25.5s11.4-25.5,25.5-25.5,25.5,11.4,25.5,25.5-11.4,25.5-25.5,25.5ZM37,21.1c-12.4,0-22.5,10.1-22.5,22.5s10.1,22.5,22.5,22.5,22.5-10.1,22.5-22.5-10.1-22.5-22.5-22.5Z"
                            fill="#231f20"
                          />
                        </g>
                        <rect
                          x="64.9"
                          y="54.3"
                          width="3"
                          height="30.1"
                          transform="translate(-29.6 71.4) rotate(-47.5)"
                          fill="#231f20"
                        />
                        <rect
                          x="25.4"
                          y="33.2"
                          width="23"
                          height="3"
                          fill="#231f20"
                        />
                        <rect
                          x="25.4"
                          y="42.1"
                          width="23"
                          height="3"
                          fill="#231f20"
                        />
                        <rect
                          x="25.4"
                          y="51"
                          width="13.2"
                          height="3"
                          fill="#231f20"
                        />
                      </g>
                    </g>
                  </svg>
                  <p>{lastSearchTerm}</p>
                </div>
              ) : (
                ""
              )}
              <div className="content-items-result-filter">
                <div className="header-products title-filtered-products">
                  {props?.search?.tag ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          <b>
                            {" "}
                            {props?.search?.tag?.toString()}{" "}
                            <span
                              onClick={() => onChangeFilter({ tag: "" })}
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ tag: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {props.search.q ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          <b>
                            {" "}
                            {props.search.q?.toString()}{" "}
                            <span
                              onClick={() => onChangeFilter({ q: "" })}
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ 1: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.search.collection ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Collection:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.collection?.toString()}{" "}
                            <span
                              onClick={() => onChangeFilter({ collection: "" })}
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ collection: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.search.by_fabric ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>By Fabric:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.by_fabric?.toString()}{" "}
                            <span
                              onClick={() => onChangeFilter({ by_fabric: "" })}
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ by_fabric: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {props.search.color_item ? (
                  <div className="search-results">
                    <p className="u-text-capitalize">
                      <>
                        <span>Color:</span>{" "}
                        <b>
                          {" "}
                          {props.search.color_item?.toString()}{" "}
                          <span
                            onClick={() => onChangeFilter({ color_item: "" })}
                          >
                            <IoCloseOutline />
                          </span>
                        </b>
                      </>
                    </p>
                  </div>
                ) : (
                  ""
                )} */}

                  {props.search.w_min && props.search.w_max ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Function:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.w_min?.toString()}g -{" "}
                            {props.search.w_max?.toString()}g
                            <span
                              onClick={() =>
                                onChangeFilter({ w_min: "", w_max: "" })
                              }
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ w_min: "", w_max: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>{" "}
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {props.search.material ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Function:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.material?.toString()}{" "}
                            <span
                              onClick={() => onChangeFilter({ material: "" })}
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ material: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>{" "}
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {props.search.function_item ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Function:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.function_item?.toString()}{" "}
                            <span
                              onClick={() =>
                                onChangeFilter({ function_item: "" })
                              }
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ function_item: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>{" "}
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.search.finish_item ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Finish:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.finish_item?.toString()}{" "}
                            <span
                              onClick={() =>
                                onChangeFilter({ finish_item: "" })
                              }
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ finish_item: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>{" "}
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.search.construction_item ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Construction:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.construction_item?.toString()}{" "}
                            <span
                              onClick={() =>
                                onChangeFilter({ construction_item: "" })
                              }
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ construction_item: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>{" "}
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.search.pattern_design ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Pattern & Design:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.pattern_design?.toString()}{" "}
                            <span
                              onClick={() =>
                                onChangeFilter({ pattern_design: "" })
                              }
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ pattern_design: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {props.search.look_feel ? (
                    <div className="search-results">
                      <p className="u-text-capitalize">
                        <>
                          {/* <span>Finish Look & Feel:</span>{" "} */}
                          <b>
                            {" "}
                            {props.search.look_feel?.toString()}{" "}
                            <span
                              onClick={() => onChangeFilter({ look_feel: "" })}
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({ look_feel: "" });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>{" "}
                          </b>
                        </>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {props?.search.min_price && props?.search.max_price ? (
                    <div className="search-results">
                      {isNaN(priceRage[0]) ? (
                        <p className="u-text-capitalize">
                          <span>Max price:</span>{" "}
                          <b>
                            {" "}
                            Over - {priceRage[1]}{" "}
                            <span
                              onClick={() =>
                                onChangeFilter({
                                  min_price: "",
                                  max_price: "",
                                })
                              }
                              role="button"
                              tabIndex="0"
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  onChangeFilter({
                                    min_price: "",
                                    max_price: "",
                                  });
                                }
                              }}
                            >
                              <IoCloseOutline />
                            </span>
                          </b>
                        </p>
                      ) : (
                        <p className="u-text-capitalize">
                          <>
                            <span>Max price:</span>
                            <b>
                              {" "}
                              ${priceRage[0]} - ${priceRage[1]}{" "}
                              <span
                                onClick={() =>
                                  onChangeFilter({
                                    min_price: "",
                                    max_price: "",
                                  })
                                }
                                role="button"
                                tabIndex="0"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" || e.key === " ") {
                                    onChangeFilter({
                                      min_price: "",
                                      max_price: "",
                                    });
                                  }
                                }}
                              >
                                <IoCloseOutline />
                              </span>
                            </b>
                          </>
                        </p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="content-tools-for-products">
                  <BsSliders
                    className="filter-button"
                    onClick={() => closeFilter()}
                  />

                  <button
                    className="btn-sort-regularprice"
                    onClick={() => handleSortByPrice()}
                  >
                    <TbArrowsSort />
                  </button>

                  <select
                    className="select-size-per-page"
                    name="selectSize"
                    defaultValue={props?.search?.size}
                    onChange={(e) => handleSelectSize(e)}
                  >
                    <option value="97">97</option>
                    <option value="194">194</option>
                    <option value="291">291</option>
                  </select>
                </div>
              </div>
              {!loading && (
                <p
                  style={{
                    marginBottom: "1em",
                    marginTop: "1em",
                    fontWeight: "bold",
                  }}
                >
                  <span>
                    {dataProducts?.products.meta?.pagination.total} product
                    found
                  </span>
                </p>
              )}
              {!loading ? (
                <>
                  <div className="row">
                    {dataProducts?.products?.data.map((item, i) => (
                      <div
                        className="col-xl-3 col-lg-4 col-md-4 col-6 u-margin-bottom-3 cd-content-button u-position-relative"
                        key={i}
                      >
                        <div className="content-like-heart">
                          <button
                            className={`btn-heart-favorite ${
                              favoriteList?.favorites?.data[0]?.attributes?.favoritelist.some(
                                (fav) =>
                                  fav.productsid === item.attributes.productsid
                              ) && "mark-heart-check"
                            }`}
                            onClick={() => hadnleAddFavorite(item)}
                          >
                            <HeartLike />
                          </button>
                        </div>

                        <CardProduct confirmed={0} data={item.attributes} />

                        {(authUser.typeCustomer === "Admin" ||
                          authUser.typeCustomer === "Special customer") && (
                          <ButtonTL
                            disabled={updateLoading}
                            color="primary"
                            weight="light"
                            size="small"
                            onClick={() => handleAddToCart(item, "swatch")}
                            style={{ width: "130px" }}
                            className="u-margin-top-2 u-margin-bottom-2"
                          >
                            {updateLoading ? <SmallLoader /> : "Swatch Request"}
                          </ButtonTL>
                        )}
                      </div>
                    ))}
                  </div>
                  <div>
                    <br />
                    <Pagination
                      activePage={dataProducts?.products.meta?.pagination.page}
                      itemsCountPerPage={
                        dataProducts?.products.meta?.pagination.pageSize
                      }
                      totalItemsCount={
                        dataProducts?.products.meta?.pagination.total
                      }
                      pageRangeDisplayed={4}
                      onChange={handlePageChange}
                    />
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
